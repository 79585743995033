import firebase from 'firebase/compat/app';
import 'firebase/compat/database';

const firebaseConfig = {
apiKey: "AIzaSyBLkHukvYLk4Vd4V7guVjCvFRHdDIBERVY",
  authDomain: "easytalkai.firebaseapp.com",
  projectId: "easytalkai",
  storageBucket: "easytalkai.appspot.com",
  messagingSenderId: "685215862051",
  appId: "1:685215862051:web:01bc82f782f3a9d1d55096",
  measurementId: "G-J8GQ2H355B",
  databaseURL: "https://easytalkai-default-rtdb.europe-west1.firebasedatabase.app/",
};

firebase.initializeApp(firebaseConfig);

const db = firebase.database();

export { db };
